export default defineAppConfig({
  title: "Luke Nelson",
  tagline: "Creative Media Producer",
  description:
    "I am a Creative Media Producer interested in Video, Web, XR, Lighting and Sound.",
  socials: [
    {
      name: "email",
      url: "mailto:hello@lukenelson.uk",
      icon: "tabler:mail",
    },
    {
      name: "mastodon",
      url: "https://social.nelson.zone/@luc122c",
      icon: "tabler:brand-mastodon",
    },
    {
      name: "threads",
      url: "https://www.threads.net/@luc122c",
      icon: "tabler:brand-threads",
    },
    {
      name: "twitter",
      url: "https://twitter.com/luc122c",
      icon: "tabler:brand-x",
    },
    {
      name: "github",
      url: "https://github.com/luc122c",
      icon: "tabler:brand-github",
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/luc122c/",
      icon: "tabler:brand-linkedin",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/luc122c",
      icon: "tabler:brand-instagram",
    },
    {
      name: "youtube",
      url: "https://www.youtube.com/luc122c",
      icon: "tabler:brand-youtube",
    },
  ] as Social[],
  navigation: [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Development Site",
      url: "https://lukenelson.dev",
      external: true,
    },
  ] as NavItem[],
});

interface Social {
  name: string;
  url: string;
  icon: string;
}

interface NavItem {
  text: string;
  url: string;
  disabled?: boolean;
  external?: boolean;
}
